export const isLocalUrl = (urlInput: string) => {
  try {
    const url = new URL(urlInput);
    return (
      url.hostname === '127.0.0.1' ||
      url.hostname === 'studio.local.apollographql.com' ||
      url.href.includes('localhost')
    );
  } catch {
    return false;
  }
};
