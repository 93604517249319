import { useLDFlag } from './launchDarkly';

export const useShouldEnableFed2Onboarding = () =>
  useLDFlag('pulsar_fed2Onboarding');

export const usePulsarShowFieldsL1Metrics = () =>
  useLDFlag('pulsar_displayListAnalyticsLargeSchemas');

export const usePulsarCommercialEntitlementsBanners = () =>
  useLDFlag('operations-l1-commercial-router-entitlements');

export const useOrionFedAlpha = () => useLDFlag('orion_fed_alpha');

export const useShowCompositionRules = () =>
  useLDFlag('orion-show-composition-rules');

export const useIsFedNextEnabled = () => useLDFlag('fed_next_enabled');

export const useShowUserProfileSurvey = () =>
  useLDFlag('growth-show-user-profile-survey');

export const useDefaultPlanID = () => useLDFlag('growth-default-studio-plan');

export const useHideConnectorsPreviewBadges = () =>
  useLDFlag('growth-hide-connectors-preview-badges');

export const useDXSchemaForConnectorsOnboarding = () =>
  useLDFlag('growth-default-dx-schema-for-connectors-onboarding');

export const useShowRegisteredAndPendingMemberPage = () =>
  useLDFlag('growth-show-pending-and-registered-member-page');
export const useGrowthShowRoverCTA = () =>
  useLDFlag('growth-show-rover-init-cta');

export const useDemoSupergraphInEnterpriseTrial = () =>
  useLDFlag('betelgeuse-demo-supergraph-in-enterprise-trial');
